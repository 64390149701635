import React from "react"
import { InfoContainer, ProfileHeader } from "./profile-info.styles"
import Image from "../Image-component/image"

const ProfileInfo = () => {
  return (
    <InfoContainer>
      <Image />
      <ProfileHeader>
        "Hello world, I like to write stuff that makes stuff happen."
      </ProfileHeader>
    </InfoContainer>
  )
}

export default ProfileInfo
