import React,{useState} from "react"

import Layout from "../components/layout"
import ProfileInfo from '../components/profile-info/profile-info.component';
import SEO from "../components/seo"
import SectionBox from '../components/section-box/section-box.component';
import ImageCarousalComponent from '../components/image-carousal/image-carousal.component';
import FloatingInfoComponent from '../components/floating-info/floating-info.component';
import {animateScroll as scroll} from 'react-scroll';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive'
import { useStaticQuery, graphql } from "gatsby";




function SectionComponent({ content}) {
  
const data = useStaticQuery(graphql`
query fbQuery {
  allFile(filter: {relativeDirectory: {eq: "floating-box-images"}}) {
    edges {
      node {
        base
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  }
}

`)

const contentData = [
  {
    header: "Projects",
    image: data.allFile.edges[3].node.childImageSharp.fluid,
    text: "A personal trainer web app created using create-react-app. Utilizing redux for state management and firebase for backend operations",
    title: "Personal Trainer Web App",
    desc: "Personal trainer web app using create-react-app using JSX, JS",
    linkTo: "https://web-pt.web.app",
  },
  {
    header: "Azure",
    image: data.allFile.edges[2].node.childImageSharp.fluid,
    text: "A mood prediction web app built using create-next-app. Using custom built API's to predict mood according to the moons rotation based on the polynesian & maori lunar calendar belief system",
    title: "Moody Web App",
    desc: "Mood prediction app built with create-next-app, primary language: JSX, JS",
    linkTo: "https://moody-app.vercel.app",
  },
  {
    header: "Microsoft Dynamics",
    image: data.allFile.edges[5].node.childImageSharp.fluid,
    text: "A kids game built using Unity running on WebGL and mobile, Based on the characters from popular NZ kids show Kiri & Lou",
    title: "Kiri & Lou Kids Game",
    desc: "Kids game built using Unity, primary language: C#",
    linkTo: "https://sheassmp.github.io/KiriAndLou/",
  },
  {
    header: "AR/VR",
    image: data.allFile.edges[4].node.childImageSharp.fluid,
    text: "Project built using Augmented Reality, Using Unity, Vuforia",
    title: "AR",
    desc: "AR/VR projects built primarily with Unity, primary language: C#",
    linkTo: "https://vimeo.com/374830640",
  },
  {
    header: "Artificial Intelligence",
    image: data.allFile.edges[0].node.childImageSharp.fluid,
    text: "Link to my Github showing the various projects I have worked and currently am working on",
    title: "GitHub",
    desc: "My GitHub Feel free to browse my current and past projects",
    linkTo: "https://github.com/Sheassmp",
  },
  {
    header: "Experience",
    image: data.allFile.edges[1].node.childImageSharp.fluid,
    text: "Love to test myself, constantly looking to expand my horizons and develop my skills",
    title: "About Me",
    desc: "Hi! I love to learn and create, utilizing my passion for programming",
    linkTo: "www.linkedin.com/in/shea-maiava-paris",
  }
];

const {
  header,
  image,
  text,
  title,
  desc,
  linkTo,
} = contentData[content] || {};


const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' })
const isTabletOrMobileDevice = useMediaQuery({
 query: '(max-device-width: 800px)'
})
const [hidden, setHidden] = useState(true);

  function openInNewTab() {
     var win = window.open(linkTo, '_blank');
     win.focus();
}

function OpenModal() {

  if(isTabletOrMobile || isTabletOrMobileDevice) {
    scroll.scrollTo(0);
  } else {
    scroll.scrollTo(1000);
  }
  setHidden(false);

}


  return (
    
    hidden ? (  
      
      <>
        <SectionBox handleClick = {() => OpenModal()} header={header} description={desc} />
      </>
      
      ) : (
        <>
            <SectionBox handleClick = {() => setHidden(false)} header={header} description={desc} />
            <FloatingInfoComponent linkTo = {linkTo} handleLinkClick={openInNewTab}  image = {image} text={text} title={title} handleClick={() => setHidden(true)} />
          </>
        )
      
        );
  }



  const IndexPage = () => {
    
  
    const StyledBoxDiv = styled.div`
    height:auto;
    display:flex;
    flex-wrap:wrap;
    padding-left:0;
    justify-content: center;
    margin: 0 auto; 
    max-width: 1200px;
    padding-bottom: 100px;
    border-bottom: groove #0000001a;

    @media screen and (max-width: 800px) {
      max-width: 600px;
      flex-direction: column;
      height: auto;
    }

`;
  
  return (
    <Layout>

    <SEO title="Shea Paris" />
      <StyledBoxDiv>
        <SectionComponent content={0} header={"Microsoft Dynamics"}/>
        <SectionComponent content={1} header={"Projects"}/>
        <SectionComponent content={2} header={"AI"}/>
        <SectionComponent content={3} header={"AR/VR"}/>
        <SectionComponent content={4} header={"Azure Experience"}/>
        <SectionComponent content={5} header={"About Me"}/>
      </StyledBoxDiv>

     <div style={{margin: `auto`,marginBottom: `100px`, marginTop: `100px`, maxWidth: `1000px`}} >
     

     <ImageCarousalComponent/>
     
     </div>
     
     <div style={{  marginBottom: `1.45rem` }}> <ProfileInfo /></div>

    </Layout>
  )
}
export default IndexPage
