import styled from 'styled-components';
import Img from "gatsby-image"

export const ProfileImage = styled(Img)`
    width: 200px;
    height: 250px;
    min-height: 250px;
    min-width: 200px;

    @media screen and (max-width: 800px) {
        width: auto;
        height: auto;
    }
`;

