import styled from "styled-components";
 import Img from "gatsby-image"
 import { Carousel } from "react-responsive-carousel"
 
  

 
   const  defaultWidth = "100vw";
   const defaultHeight = "55vh";
   const defaultPadding = "75px";
   const textColor = "#f5f5f5";
   const emphasisColor = "#ccc";
   const linkColor = "#D6D600";

   const slidePrimaryColor = `#f82222b8`;
   const slideSecondaryColor = "#25292c";
   const slideContentColor = "#25292c";
  


 export const ImageCarousal = styled(Carousel)`
 
      .my-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: ${defaultHeight};
        line-height: 1.5;
        font-size: 1.5em;
        color: ${textColor};
     
        h1, h2, h3, h4, h5, h6, p, ul, ol {
          border: 0;
          margin: 10px auto;
          padding: 0;
          display: inline-block;
          width: 100%;
        }
        &.complex {
          text-align: left;
          flex-direction: column;
          justify-content: flex-start;
          h1, h2, h3, h4, h5, h6, p, ul, ol {
            margin-bottom: 30px;
          }
        }
        ul, ol {
          margin: 10px;
          padding-left: 75px;
        }
        ol li {
          list-style: decimal !important;
        }
        ul li {
          list-style: disc;
        }
        p {
          font-size: 1.2em;
        }
        strong, em {
          color: ${emphasisColor};
        }
        a {
          color: ${linkColor};
          border-bottom: 3px dotted $link-color;
          text-decoration: none;
        }
        iframe, img {
          width: ${defaultWidth};
          height: ${defaultHeight};
          margin: - ${defaultPadding};
        }
        ul, ol, li {
          text-align: left;
        }
        blockquote {
          position: relative;
          color: ${textColor};
          text-align: left;
          border-left: 10px solid ${emphasisColor};
          margin: 1.5em 10px;
          padding: 0.5em 10px;
          padding-left: 50px;
          quotes: "\201C""\201D""\2018""\2019";
        }
        blockquote:before {
          color: #ccc;
          content: open-quote;
          position: absolute;
          left: 10px;
          top: 30px;
          font-size: 4em;
          line-height: 0.1em;
          margin-right: 0.25em;
          vertical-align: -0.4em;
        }
        blockquote p {
          display: inline;
        }
        &.primary {
          background-color: ${slidePrimaryColor};
        }
        &.secondary {
          background-color: ${slideSecondaryColor};
        }
        &.content {
          background-color: ${slideContentColor};
        }
      }
    
 `;


export const ImageSlide = styled(Img)`
    width: 100%;
    @media screen and (max-width: 800px) {
      
      height: 33vh;
		}
`;

export const SlideContainer = styled.div`
  border: double #e2e2e2;
`;
