import styled from 'styled-components';

export const InfoContainer = styled.div`
    background: white;
    box-shadow: 7px 11px 29px -4px rgba(0,0,0,0.75);
    display: flex;
    border-radius: 2px;
    
    max-width: 1200px;
    margin: auto;
    margin-bottom: 4.45rem;

    @media screen and (max-width: 800px) {
     max-width: 340px;  
     flex-direction: column;
     
    }
`;

export const ProfileHeader = styled.h3`
    color: black;

    padding: 15px;
    margin: auto;
    align-self: center;
    text-align: center;

    @media screen and (max-width: 800px) {
        font-size: larger;
        top: 50px;
        left: 0;
    }
`;
