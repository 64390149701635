import React from "react"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { ImageSlide, ImageCarousal, SlideContainer } from "./image-carousal.styles"
import WithSpinner from "../with-spinner/with-spinner.component"

import { useStaticQuery, graphql } from "gatsby"




const CarouselWithSpinner = WithSpinner(ImageCarousal)

const ImageCarouselComponent = () => {
  const data =  useStaticQuery(graphql`
  query slideQuery {
      allFile(filter: {relativeDirectory: {eq: "slide-images"}}) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 2000, maxHeight: 1000) {
                aspectRatio
                base64
                sizes
                src
                srcSet
              }
            }
          }
        }
      }
    }
  `)
    

  return (
    <SlideContainer>
        <CarouselWithSpinner
          className="presentation-mode"
          stopOnHover={true}
          infiniteLoop={true}
          autoPlay={true}
          swipeable={false}
          showArrows={true}
          transitionTime={1500}
          interval={15000}
          showThumbs={false}
        >
            <div key="content-0" style={{background: '#f82222b8'}}  className="my-slide primary">
              <h1>Project Snapshots</h1>
            </div>
            <div className= "my-slide secondary" >
              <ImageSlide   fluid={data.allFile.edges[0].node.childImageSharp.fluid} alt="stars" />
            </div>
            <div className="my-slide secondary">
              <ImageSlide  fluid={data.allFile.edges[1].node.childImageSharp.fluid} alt="stars"  />
            </div>
            <div className="my-slide content">
              <ImageSlide  fluid={data.allFile.edges[2].node.childImageSharp.fluid} alt="stars" />
            </div>
            <div className="my-slide content">
              <ImageSlide  fluid={data.allFile.edges[3].node.childImageSharp.fluid} alt="stars" />
            </div>
           
        </CarouselWithSpinner>
      </SlideContainer>
      )}
  


export default ImageCarouselComponent;
